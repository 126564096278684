<template>
  <v-container v-if="!loading" fluid class="pa-0">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      class="mx-auto"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <h1 class="text-center primary--text" v-bind="attrs" v-on="on">
          {{ calendarTitle }}
        </h1>
      </template>
      <v-date-picker
        v-model="month"
        :first-day-of-week="1"
        no-title
        scrollable
        type="month"
        event-color="error"
        @click:date="menu = false;"
      ></v-date-picker>
    </v-menu>
    <v-calendar
      class="mx-auto"
      color="primary"
      ref="calendar"
      v-model="focus"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      :weekday-format="formatWeekDay"
      >
      <template v-slot:day-label="{ day, date, present, past }" class="text-center" v-ripple>
        <v-badge
          overlap
          :value="!!modelo.some(m => m.date == date) && modelo.find(m => m.date == date)?.observaciones"
          :color="$vuetify.theme.dark ? 'white' : 'black'"
          icon="mdi-account-voice"
          :class="$vuetify.theme.dark ? 'dark-badge' : 'light-badge'"
        >
          <div :class="'calDay d-flex align-center justify-center mx-auto my-1' + (present ? ' primary--text' : '')" @contextmenu.prevent="addNote(date, (modelo.find(m => m.date == date)?.observaciones || ''))" @click="changeEstado(date)" :style="'border-radius: 10px;background-color:' + statusColors[(modelo.find(m => m.date == date)?.idEstadoUnidadesEquipo || 6)-3]">
            {{ day }}
          </div>
        </v-badge>
      </template>
    </v-calendar>
    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="error" fab @click="cancelar" large class="me-4" v-if="changed">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" fab @click="setDisponibilidad" large>
        <v-icon>mdi-floppy</v-icon>
      </v-btn>
    </div>
    <ConfirmDialog ref="confirm" />
    <AddNote ref="addNote" />
    <ChangePwd ref="changePwd" />
  </v-container>
  <v-container v-else>
    <div style="width: 100%;height: 90vh;" class="d-flex align-center justify-center">
      <v-progress-circular
        size="250"
        indeterminate
        color="primary"
      >
        <h2>Cargando</h2>
      </v-progress-circular>
    </div>
  </v-container>
</template>

<script>

export default {
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    ChangePwd: () => import("../components/ChangePwd.vue"),
    AddNote: () => import("../components/AddNote.vue"),
  },
  data() {
    return {
      loading: false,
      menu: false,
      modelo:[],
      month: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10),
      focus: new Date().getTime(),
      calendarTitle: ''
    };
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.getDisponibilidad(),
    ])
    this.loading = false
    this.$nextTick(() =>{
      if (!localStorage.getItem("pwdChanged")){
        console.log(localStorage.getItem("pwdChanged"));
        this.changePwd()
      }
    })
  },
  watch:{
    "month":{
      handler(m){
        this.$set(this.$data, 'month', this.month)
        this.$set(this.$data, 'focus', new Date(this.month + "-10").getTime())
        this.$nextTick(() => {
          this.calendarTitle = this.$refs.calendar.title.toUpperCase();
        });
      }
    }
  },
  computed:{
    changed(){
      return this.modelo.some(x => x.changed)
    },
    statusColors(){
      return ['var(--v-success-base)','var(--v-error-base)','var(--v-warning-base)','']
    },
  },
  methods: {
    formatWeekDay(d){
      return ["L","M","X","J","V","S","D"][d.day-1]
    },
    async addNote(date,note){
      const newNote = (await this.$refs["addNote"].open(
        "Añadir observaciones",
        { color: "primary" },
        note
      ));
      if (!newNote) return
      var ind = this.modelo.findIndex(m => m.date == date)
      if (ind != -1) {
        this.$set(this.modelo[ind], "observaciones", newNote)
        this.$set(this.modelo[ind], "changed", true)
      }
      else this.$set(this.modelo, this.modelo.length, { idEstadoUnidadesEquipo: 6, date : date, changed: true, observaciones: newNote })
    },
    async changePwd(){
      const newPwd = (await this.$refs["changePwd"].open(
      "Establecer una contraseña",
      "Parece que es la primera vez que inicias sesión. Por favor, crea una contraseña segura.",
      { color: "primary" }
      ));
      if (!newPwd) location.reload()
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    move(t) {
      this.$refs.calendar.move(t);
    },
    changeEstado(d){
      var ind = this.modelo.findIndex(mod => mod.date == d)
      if (ind == -1){
        this.$set(this.modelo, this.modelo.length, { date: d, idEstadoUnidadesEquipo: 3, observaciones: '', changed: true })
      }
      else{
        this.$set(this.modelo[ind],'idEstadoUnidadesEquipo',((this.modelo[ind].idEstadoUnidadesEquipo + 2) % 4) + 3)
        this.$set(this.modelo[ind],'changed',true)
      }
    },
    async getDisponibilidad() {
      this.loading = true
      this.$set(this.$data, 'modelo', [])
      if (this.$refs.calendar) {
        this.calendarTitle = this.$refs.calendar.title.toUpperCase();
      }
      const { data } = await axios({
        url: `/disponibilidad/estadoEquipo`,
      });
      data.forEach(dia => {
        this.modelo.push(dia)
      });
      this.loading = false
    },
    async setDisponibilidad() {
      this.loading = true
      try {
        const { data } = await axios({
          method: "POST",
          url: `/disponibilidad/estadoEquipo`,
          data: this.modelo.filter(x => x.changed)
        });
        this.$root.$emit("snack", "Se han guardado los cambios correctamente");
        this.modelo.forEach(m => {
          m.changed = false
        })
      this.loading = false
      } catch (error) {
        console.log(error);
        this.$root.$emit("snack", "No se han podido guardar los cambios");
        this.loading = false
      }
    },
    async cancelar(){
      const confirmation = (await this.$refs.confirm.open(
        "Descartar cambios",
        "¿Descartar los cambios? Esta acción es irreversible.",
        { color: "error" }
        ));
      if (!confirmation) return;
      this.getDisponibilidad()
    }
  },
};
</script>

<style>
.calDay{
  height: 40px !important;
  width: 40px !important;
  transition: background-color 0.1s ease;
}
.dark-badge .v-badge__badge .v-icon {
  color: black;
}
.light-badge .v-badge__badge .v-icon {
  color: white;
}
</style>